@import './abstracts/variables';

@import './vendors/fonts';
@import './vendors/theme';

body {
  font-family: 'Barlow';
  font-weight: 400;
}

p {
  margin: 0;
}