:host {
  --primary-ocean-floor: #1F2C4A;
  --primary-caribbean-blue: #0093DD;
  --primary-paradise-yellow: #FFF500;
  --secondary-green: #00DD8D;
  --secondary-yellow: #FFB900;
  --secondary-red: #FF1F00;
  --secondary-blue: #899ECD;
  --secondary-gray: #B2B7C7;
  --secondary-gray-light: #F1F3F7;
}